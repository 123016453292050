<template>
  <div class="friendsSidebar">
    <h1>Friends</h1>

    <button
      ref="sendFriendRequest"
      :class="[
        'sendFriendRequest',
        selectedFromSidebar == 'send' ? 'selectedFromSidebar' : '',
      ]"
      @click="
        selectedFromSidebar = 'send';
        $emit('selected', 'send');
      "
    >
      <AddIcon />Add friend…
    </button>

    <div class="divider"></div>

    <!-- INBOX (incoming friend requests) -->
    <h2>Inbox</h2>

    <div class="listOfPeople">
      <p v-if="!friends.incoming.length">You've Caught Up With Your Inbox!</p>

      <button
        v-for="friend in friends.incoming"
        :key="friend.friendship_id"
        tabindex="-1"
      >
        <span class="monogram">{{ getMonogram(friend.user.name) }}</span>

        <p>{{ friend.user.name }}</p>

        <span class="manageFriendRequestButtons">
          <button title="Decline" @click="deleteRequest(friend)">
            <CancelIcon />
          </button>

          <button title="Accept" @click="acceptRequest(friend)">
            <CheckmarkIcon />
          </button>
        </span>
      </button>
    </div>

    <!-- OUTBOX (outgoing friend requests) -->
    <h2>Outbox</h2>

    <div class="listOfPeople">
      <p v-if="!friends.outgoing.length">Your Outbox Is Empty!</p>

      <button
        v-for="friend in friends.outgoing"
        :key="friend.friendship_id"
        tabindex="-1"
      >
        <span class="monogram">{{ getMonogram(friend.user.name) }}</span>

        <p>{{ friend.user.name }}</p>

        <span class="manageFriendRequestButtons">
          <button title="Cancel" @click="deleteRequest(friend)">
            <CancelIcon />
          </button>
        </span>
      </button>
    </div>

    <!-- YOUR FRIENDS (current friends) -->
    <h2>Your Friends</h2>

    <div class="currentFriends listOfPeople">
      <p v-if="!friends.current.length">You're Not Friends With Anyone Yet!</p>

      <button
        v-for="friend in friends.current"
        :key="friend.friendship_id"
        @click="
          selectedFromSidebar = friend.friendship_id;
          $emit('selected', friend.friendship_id);
        "
        :class="selectedFromSidebar == friend.friendship_id ? 'selectedFromSidebar' : ''"
      >
        <span class="monogram">{{ getMonogram(friend.user.name) }}</span>

        <p>{{ friend.user.name }}</p>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
// Imports
import store from "@/store";
import axios from "axios";
import { ComputedRef, computed, ref } from "vue";
import AddIcon from "@/components/Icons/AddIcon.vue";
import CheckmarkIcon from "@/components/Icons/CheckmarkIcon.vue";
import CancelIcon from "@/components/Icons/CancelIcon.vue";

// Type declations
type Friendship = {
  friendship_id: string;
  user: {
    user_id: string;
    name: string;
    avatar?: string;
  };
};

const emit = defineEmits(["selected"]);

// Refs/Props/Computed
const friends: ComputedRef<Record<string, Friendship[]>> = computed(
  () => store.state.friends
);
const selectedFromSidebar = ref("");

// FUNCTIONS

// Accept incoming friend request
async function acceptRequest(friendship: Friendship) {
  const friendship_id = friendship.friendship_id;
  try {
    await axios.patch("/friendships/pending", { friendship_id: friendship_id });

    store.dispatch("approveFriendship", friendship_id);

    selectedFromSidebar.value = friendship_id;

    emit("selected", friendship_id);
  } catch (err) {
    console.log(err);
  }
}
async function deleteRequest(friendship: Friendship) {
  const friendship_id = friendship.friendship_id;
  await sleep(500);

  try {
    await axios.delete("/friendships/delete", {
      data: { friendship_id: friendship_id },
    });

    store.dispatch("deleteFriendship", friendship_id);

    if (selectedFromSidebar.value == friendship_id) {
      selectedFromSidebar.value = "send";
    }
    // sending.value = false;
  } catch (err) {
    console.log(err);
    // sending.value = false;
  }
}

// Utils
async function sleep(milliseconds: number) {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve("done");
    }, milliseconds);
  });
}
function getMonogram(name: string) {
  return name.slice(0, 1).toUpperCase();
}
</script>

<style scoped>
/* Main sidebar stuff */
.friendsSidebar button {
  color: black;
  background: none;
  border: none;
  padding: 0.5em 0.75em;
  border-radius: 0.5em;
  height: 2.5em;
  width: 100%;
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.friendsSidebar {
  padding: 1em;
  height: 100%;
  width: 20em;
  border-right: #e6e6e6 solid 1px;
}

/* Main buttons for people */
.listOfPeople button {
  padding: 0.5em;
}
button > p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.monogram {
  background-color: #868686;
  height: 2em;
  width: 2em;
  border-radius: 100%;
  color: white;
  font-weight: bold;
  line-height: 2em;
  flex-shrink: 0;
}

/* Send friend request button */
.sendFriendRequest {
  margin-top: 0.25em;
}
.sendFriendRequest,
.currentFriends button {
  cursor: pointer;
}
.sendFriendRequest:hover,
.currentFriends button:hover {
  background-color: #f0f0f0;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
  margin: 0.25em 0;
}

/* Buttons that accept/decline friendships */
.manageFriendRequestButtons {
  margin-left: auto;
  display: flex;
  gap: 0.5em;
  flex-shrink: 0;
}
.manageFriendRequestButtons > button {
  background-color: #e6e6e6;
  width: 2em;
  height: 2em;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.manageFriendRequestButtons > button > svg {
  width: 1em;
  height: 1em;
}

/* Headings and text */
h1 {
  font-family: "Oceanwide", "Helvetica", "Arial", sans-serif;
  font-size: 1.5em;
}
h2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  margin: 1.5em 0 0.5em 0;
}
.listOfPeople > p {
  color: #afafaf;
  font-size: 1em;
  margin: 0.25em 0 0 0.25em;
}

/* Icons */
.sendFriendRequest > svg {
  width: 1.25em;
  height: 1.5em;
}

/* Selected button */
.selectedFromSidebar,
.selectedFromSidebar:hover {
  background-color: #e6e6e6 !important;
}
</style>
