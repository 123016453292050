<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25.001"
    viewBox="0 0 25 25.001"
  >
    <g id="Settings_Icon" data-name="Settings Icon" transform="translate(-4.392 -4.392)">
      <path
        id="Path_3"
        data-name="Path 3"
        d="M16,11.1a4.9,4.9,0,1,0,3.461,1.434A4.9,4.9,0,0,0,16,11.1Zm0,7.79A2.9,2.9,0,1,1,18.9,16,2.9,2.9,0,0,1,16,18.9Z"
        transform="translate(0.892 0.891)"
        fill="#fff"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M28.575,13.851l-2.028-.505q-.145-.4-.321-.776L27.3,10.781a1.078,1.078,0,0,0-.16-1.317L24.319,6.64A1.076,1.076,0,0,0,23,6.479L21.212,7.557q-.378-.174-.775-.319l-.5-2.03a1.078,1.078,0,0,0-1.046-.816H14.9a1.077,1.077,0,0,0-1.044.816l-.505,2.029c-.264.1-.522.2-.774.319l-1.79-1.077a1.077,1.077,0,0,0-1.316.162L6.641,9.465a1.077,1.077,0,0,0-.162,1.317l1.077,1.79c-.115.251-.222.51-.319.773L5.21,13.85A1.078,1.078,0,0,0,4.393,14.9v3.994a1.079,1.079,0,0,0,.817,1.046l2.029.505c.1.264.2.521.319.773L6.48,23a1.078,1.078,0,0,0,.162,1.317l2.823,2.824a1.073,1.073,0,0,0,1.316.16l1.792-1.077c.251.116.51.223.773.32l.505,2.028a1.077,1.077,0,0,0,1.044.818H18.89a1.079,1.079,0,0,0,1.046-.816l.5-2.03c.264-.1.521-.2.774-.32L23,27.3a1.076,1.076,0,0,0,1.317-.16l2.824-2.823A1.076,1.076,0,0,0,27.3,23l-1.077-1.789c.116-.251.224-.511.321-.776l2.028-.5a1.078,1.078,0,0,0,.816-1.046V14.9A1.079,1.079,0,0,0,28.575,13.851Zm-1.338,4.2-1.785.443a1.079,1.079,0,0,0-.772.738,7.966,7.966,0,0,1-.627,1.52,1.075,1.075,0,0,0,.023,1.068l.949,1.575-1.635,1.634-1.574-.948a1.071,1.071,0,0,0-1.066-.025,8.14,8.14,0,0,1-1.523.628,1.077,1.077,0,0,0-.737.772l-.444,1.786H15.735l-.445-1.785a1.076,1.076,0,0,0-.735-.772,8.146,8.146,0,0,1-1.522-.629,1.081,1.081,0,0,0-1.066.025l-1.576.948L8.757,23.389,9.7,21.814a1.076,1.076,0,0,0,.025-1.067,8.13,8.13,0,0,1-.629-1.52,1.076,1.076,0,0,0-.771-.735l-1.786-.445V15.735l1.785-.444a1.078,1.078,0,0,0,.772-.735,8.036,8.036,0,0,1,.628-1.522A1.078,1.078,0,0,0,9.7,11.967l-.948-1.575,1.635-1.634,1.575.948a1.075,1.075,0,0,0,1.067.024,8.1,8.1,0,0,1,1.52-.627,1.077,1.077,0,0,0,.737-.772l.445-1.785h2.31l.444,1.785a1.077,1.077,0,0,0,.737.772,8.083,8.083,0,0,1,1.522.628,1.081,1.081,0,0,0,1.067-.024l1.576-.949,1.632,1.634-.949,1.574a1.074,1.074,0,0,0-.023,1.069,7.956,7.956,0,0,1,.627,1.519,1.075,1.075,0,0,0,.772.737l1.785.446v2.309Z"
        fill="#fff"
      />
    </g>
  </svg>
</template>
