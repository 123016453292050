<template>
  <div class="authContainer">
    <LogoIcon style="height: 1.5em" />

    <h1>{{ heading }}</h1>
    <p>{{ subheading }}</p>

    <div v-show="!showCreateAccount">
      <input
        type="email"
        ref="email"
        placeholder="Your email…"
        maxlength="255"
        :class="error ? 'error' : ''"
        @keydown="error = ''"
        @keydown.enter="createSession()"
      />
    </div>

    <div v-show="showCreateAccount" class="createAcountContainer">
      <img src="@/assets/generic_user.svg" />

      <div>
        <input
          type="text"
          ref="name"
          maxlength="255"
          placeholder="Your name…"
          :class="error ? 'error' : ''"
          @keydown="error = ''"
          @keydown.enter="createAccount"
        />
      </div>
    </div>

    <p v-if="error" class="error">
      <span><ErrorIcon /></span>
      {{ error }}
    </p>

    <div class="buttons">
      <HeyButton
        @click="showCreateAccount ? showLogInScreen() : router.push('/')"
        type="secondary"
      >
        <BackIcon />
        Go Back
      </HeyButton>

      <HeyButton
        @click="showCreateAccount ? createAccount() : createSession()"
        :loading="loading"
      >
        Continue
        <NextIcon />
      </HeyButton>
    </div>
  </div>
</template>

<script setup lang="ts">
// Imports
import HeyButton from "@/components/HeyButton.vue";
import NextIcon from "@/components/Icons/NextIcon.vue";
import BackIcon from "@/components/Icons/BackIcon.vue";
import ErrorIcon from "@/components/Icons/ErrorIcon.vue";
import LogoIcon from "@/components/Icons/LogoIcon.vue";
import router from "@/router";
import axios, { AxiosError } from "axios";
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import sleep from "@/sleep";

// Vuex Store
const store = useStore();
computed(() => store.state.authStatus);

// Variables - UI
const heading = ref("");
const subheading = ref("");
const showCreateAccount = ref(false);
const error = ref("");
const loading = ref(false);

// Variables - HTML Inputs
const email = ref<HTMLInputElement | null>(null);
const name = ref<HTMLInputElement | null>(null);

// Switch to Log In View
function showLogInScreen() {
  showCreateAccount.value = false;

  heading.value = "Enter your email";
  subheading.value = "First time using Hey? An account will be created for you";
  error.value = "";
}

// Switch to Create Account View
function showCreateAccountScreen() {
  showCreateAccount.value = true;

  heading.value = "Customize profile";
  subheading.value = "";
  error.value = "";
}

function isEmailValid(email: string | undefined) {
  // Valid Email Regular Expression
  const emailRegex = /^[^\s@]+@[^\s@.]+\.[^\s@.][^\s@]*$/;

  // Do validations on email
  if (!email) {
    error.value = "Email is required";
  } else if (!emailRegex.test(email)) {
    error.value = "Invalid email";
  }

  return error.value === "";
}

// Create unverified session
async function createSession() {
  // Don't allow duplicate requests if one's already processing/there's an error that hasn't been addressed
  if (loading.value || error.value) return;

  loading.value = true;
  await sleep(500); // Pause script so user can see loading spinner on button in case request is really short

  // Email Validation
  if (!isEmailValid(email.value?.value)) {
    return (loading.value = false);
  }

  try {
    // Send HTTP reuqest to create session
    await axios.post("/sessions/create", { email: email.value?.value });

    // Update Vuex Store To Set Auth Status To 1
    store.dispatch("createSession");

    loading.value = false;

    router.push("/verify");
  } catch (error) {
    loading.value = false;

    if (error instanceof AxiosError && error.response?.status == 404) {
      return showCreateAccountScreen();
    }

    throw error;
  }
}

// Create user account
async function createAccount() {
  // Don't allow duplicate requests if one's already processing/there's an error that hasn't been addressed
  if (loading.value || error.value) return;

  loading.value = true;
  await sleep(500); // Pause script so user can see loading spinner on button in case request is really short

  // Email Validation
  if (!name.value?.value) {
    error.value = "Name is required";
    return (loading.value = false);
  }

  try {
    const data = { email: email.value?.value, name: name.value?.value };

    // Send HTTP reuqest to create account
    await axios.post("/users/create", data);
  } catch (error) {
    loading.value = false;

    if (error instanceof AxiosError && error.response?.status == 404) {
      return showCreateAccountScreen();
    }

    throw error;
  }

  try {
    // Send HTTP reuqest to create session
    await axios.post("/sessions/create", { email: email.value?.value });

    // Update Vuex Store To Set Auth Status To 1
    store.dispatch("createSession");

    router.push("/verify");
  } catch {
    showLogInScreen();
    error.value = "Account created successfully, failed to create session";
  }

  loading.value = false;
}

onMounted(showLogInScreen);
</script>

<style scoped>
.authContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
}

h1 {
  font-family: "Oceanwide", "Helvetica", "Arial", sans-serif;
  font-size: 2em;
  margin-top: 0.5em;
}

p {
  color: #afafaf;
}

input {
  margin-top: 1em;
  font-size: 2em;
  width: 15em;
  border: #e6e6e6 1px solid;
  border-radius: 0.25em;
  padding: 0.5em;
  outline: none;
}
input::placeholder {
  color: #afafaf;
}

p.error {
  color: #ff0000;
  font-weight: bold;
  margin-top: 0.5em;

  display: flex;
  align-items: center;
  gap: 0.25em;
}
p.error > span {
  background-color: #ff0000;
  height: 1.5em;
  width: 1.5em;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
p.error > span > * {
  height: 1em;
}
input.error {
  border: #ff0000 1px solid;
  color: #ff0000;
  background-color: #ffe5e5;
}
input.error::placeholder {
  color: #ff0000;
}

.buttons {
  margin-top: 2em;
  display: flex;
  gap: 1em;
  align-self: end;
}

.createAcountContainer {
  display: flex;
  gap: 1em;
  align-items: center;
  margin-top: 1em;
}
.createAcountContainer > img {
  height: 5em;
}
.createAcountContainer input {
  font-size: 1.25em;
  padding: 0.75em;
  border-radius: 0.5em;
  margin-top: 0em;
}
</style>
