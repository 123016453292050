<template>
  <div class="flex-container">
    <MainSidebar />

    <div class="settings-container">
      <div class="topBar">
        <span class="monogram">
          {{ current_user?.name.slice(0, 1).toUpperCase() }}
        </span>
        <h1>{{ current_user?.name }}</h1>
      </div>

      <div class="messages" v-if="loading && !store.state.current_messages.length">
        <div class="loadingMessages">
          <TheLoading
            background="#fff"
            loader_main="#afafaf"
            loader_secondary="#e6e6e6"
          />
          <p>Loading messages…</p>
        </div>
      </div>

      <div class="messages" v-else-if="!loading && !store.state.current_messages.length">
        <div class="loadingMessages">
          <p>No messages. Say “Hey!” to {{ current_user?.name }} 👋</p>
        </div>
      </div>

      <div class="messages" @scroll="checkScrollHeight" v-else>
        <div
          v-for="message in store.state.current_messages"
          :key="message.message_id"
          :class="[
            message.sent_by == current_user?.user_id ? 'received' : 'sent',
            'message',
          ]"
        >
          {{ message.message }}
        </div>

        <div class="info" v-if="loading">
          <TheLoading
            background="#fff"
            loader_main="#afafaf"
            loader_secondary="#e6e6e6"
          />
          <p>Loading more messages…</p>
        </div>

        <div class="info" v-if="!loading && !next_cursor">
          <p>You started a conversation with {{ current_user?.name }}.</p>
        </div>
      </div>

      <textarea
        ref="message_textarea"
        placeholder="Type message…"
        rows="1"
        cols="1"
        @keydown.enter="sendMessageOrAddLineBreak"
        @input="handleInput"
      ></textarea>

      <p class="characterCount" v-if="characters > 500">
        <span :style="characters > 1000 ? 'color: red' : 'color: inherit'">{{
          characters
        }}</span
        >/1000
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheLoading from "@/components/TheLoading.vue";
import MainSidebar from "@/components/MainSidebar.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import { ComputedRef, computed, onBeforeUnmount, onMounted, onUpdated, ref } from "vue";

function checkScrollHeight(event: Event) {
  const messagesContainer = event.target as HTMLDivElement;

  if (
    messagesContainer.scrollHeight + messagesContainer.scrollTop - 100 <=
      messagesContainer.offsetHeight &&
    next_cursor.value
  ) {
    getMoreMessages();
  }
}

async function getMoreMessages() {
  if (loading.value) return;
  loading.value = true;

  await sleep(500);

  try {
    const { data } = await axios.post("/messages/get", {
      friend_id: current_user.value?.user_id,
      group_chat: false,
      cursor: next_cursor.value,
    });

    store.state.current_messages = store.state.current_messages.concat(data.messages);
    next_cursor.value = data.next_cursor;

    loading.value = false;
  } catch (err) {
    console.log(err);
    loading.value = false;
  }
}

function handleInput() {
  const textarea = message_textarea.value as HTMLTextAreaElement;
  changeTextAreaHeight();
  characters.value = textarea.value.length;
}

function changeTextAreaHeight() {
  // Reset height of message input for new window size
  const textarea = message_textarea.value as HTMLTextAreaElement;

  textarea.style.height = "auto";
  textarea.style.height = textarea.scrollHeight + 2 + "px"; // +2 is for the top and botton border
}

async function sendMessageOrAddLineBreak(event: KeyboardEvent) {
  if (event.shiftKey) return;

  event.preventDefault();

  const textarea = message_textarea.value as HTMLTextAreaElement;
  const message_text = textarea.value.trim();

  // If empty, don't send request
  if (!message_text) return;

  if (message_text.length > 1000) {
    alert("The limit is 1000 characters per message.");
    return;
  }

  const { data } = await axios.post("/messages/create", {
    group_chat: false,
    friend_id: current_user.value?.user_id,
    message_text,
  });

  store.state.current_messages.unshift(data);
  textarea.value = "";
  characters.value = 0;
  changeTextAreaHeight();

  document.querySelector(".messages")?.children[0].scrollIntoView();
}

addEventListener("resize", changeTextAreaHeight);

onBeforeUnmount(() => {
  store.state.current_messages = [];
  removeEventListener("resize", changeTextAreaHeight);
});

onUpdated(() => {
  if (!current_user.value) {
    router.push("/overview");
  }
});

// Types
type Friendship = {
  friendship_id: string;
  user: User;
};
type User = {
  user_id: string;
  name: string;
  avatar?: string;
};

// Refs
const message_textarea = ref<HTMLTextAreaElement | null>(null);
const next_cursor = ref<string | null>(null);
const loading = ref(true);
const characters = ref(0);

const current_user: ComputedRef<User | undefined> = computed(() => {
  const user_id = router.currentRoute.value.params?.user_id;
  let user;

  for (const i in store.state.friends.current) {
    const friend = store.state.friends.current[i] as Friendship;

    if (friend.user.user_id == user_id) {
      user = friend.user;
    }
  }

  return user;
});

// Lifecycle hook
onMounted(async () => {
  await sleep(500);

  try {
    const { data } = await axios.post("/messages/get", {
      friend_id: current_user.value?.user_id,
      group_chat: false,
    });

    store.state.current_messages = data.messages;
    next_cursor.value = data.next_cursor;

    loading.value = false;
  } catch (err) {
    console.log(err);
    loading.value = false;
  }
});

async function sleep(milliseconds: number) {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve("done");
    }, milliseconds);
  });
}
</script>

<style scoped>
h1 {
  font-family: "Oceanwide", "Helvetica", "Arial", sans-serif;
  font-size: 1.5em;
}

.settings-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.flex-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.topBar {
  display: flex;
  align-items: baseline;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  padding: 0.5em 1em;
  gap: 0.5em;
}

.monogram {
  background-color: #868686;
  height: 2em;
  width: 2em;
  border-radius: 100%;
  color: white;

  font-weight: bold;
  line-height: 2em;
  flex-shrink: 0;
  text-align: center;
  font-size: 1.25em;
}

.messages {
  flex: 1;
  display: flex;
  padding: 1.5em 1em;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column-reverse;
  gap: 0.25em;

  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 0));
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 0));
}

.message {
  padding: 0.5em 1em;
  line-height: 1lh;
  border-radius: 1.25em;
  max-width: 40em;
  word-break: break-word;
}

.received {
  align-self: flex-start;
  background-color: #000;
  color: white;
}

.sent + .received {
  margin-bottom: 1em;
}

.received + .sent {
  margin-bottom: 1em;
}

.sent {
  align-self: flex-end;
  background-color: #e6e6e6;
}

.info,
.loadingMessages {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  padding: 1.5em;
}

.loadingMessages {
  margin: auto;
}
.loadingMessages > p,
.info > p {
  color: #afafaf;
}

textarea {
  font-size: 1em;
  margin: 0 1em 1em 1em;
  resize: none;
  outline: none;
  border: #e6e6e6 1px solid;
  color: black;
  border-radius: 1.25em;
  padding: 0.5em 1em;
  overflow-y: scroll;
  line-height: 1lh;
  max-height: 6lh;
  scroll-padding: 0.5em;
}

textarea::placeholder {
  color: #8b8b8b;
}

.characterCount {
  position: absolute;
  bottom: 0.1em;
  right: 1.35em;
  font-size: 0.75em;
  color: #8b8b8b;
}
</style>
