<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    style="enable-background: new 0 0 20 20"
    xml:space="preserve"
  >
    <g id="Friends_Icon_Square" transform="translate(-3 -137)">
      <g id="Friend_Icon" transform="translate(-102.697 -28.5)">
        <g id="Ellipse_12" transform="translate(110.197 165.5)">
          <g>
            <circle class="st0" cx="5.5" cy="5.5" r="5.5" />
          </g>
          <g>
            <path
              class="st1"
              d="M5.5,11C2.5,11,0,8.5,0,5.5S2.5,0,5.5,0c3,0,5.5,2.5,5.5,5.5S8.5,11,5.5,11z M5.5,2C3.6,2,2,3.6,2,5.5
					S3.6,9,5.5,9S9,7.4,9,5.5S7.4,2,5.5,2z"
            />
          </g>
        </g>
        <g id="Ellipse_13" transform="translate(107.697 174.5)">
          <g>
            <ellipse class="st0" cx="8" cy="5.5" rx="8" ry="5.5" />
          </g>
          <g>
            <path
              class="st1"
              d="M8,11c-4.5,0-8-2.4-8-5.5S3.5,0,8,0c4.5,0,8,2.4,8,5.5S12.5,11,8,11z M8,2C4.7,2,2,3.6,2,5.5S4.7,9,8,9
					c3.3,0,6-1.6,6-3.5S11.3,2,8,2z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: none;
}
.st1 {
  fill: #fff;
}
</style>
