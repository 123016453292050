<template>
  <router-view :key="router.currentRoute.value.fullPath" />

  <div class="notificationManager">
    <TransitionGroup name="notif" @before-leave="keepYOffsetSame">
      <div
        class="notification"
        v-for="notifcation in notifications"
        :key="notifcation.id"
      >
        <p class="notificationTitle">{{ notifcation.title }}</p>
        <p class="notificationSubtitle" v-if="notifcation.subtitle">
          {{ notifcation.subtitle }}
        </p>
        <p class="notificationText">{{ notifcation.text }}</p>
        <button class="notificationButton" @click="deleteNotif(notifcation.id)">
          Close
        </button>
      </div>
    </TransitionGroup>
  </div>

  <div class="updateScreen" v-if="!lastUpdated">
    <h1>Update required</h1>

    <p>To update Hey to the latest version, you need to log in again.</p>

    <HeyButton @click="logout">Log out <NextIcon /></HeyButton>
  </div>
</template>

<script setup lang="ts">
// Libraries and Imports
import router from "@/router";
import axios from "axios";
import { ComputedRef, computed } from "vue";
import { useStore } from "vuex";

// Variables
const store = useStore();

// Types
type Notification = {
  text: string;
  title: string;
  subtitle?: string;
  id: string;
};

if (store.state.authStatus == 2) {
  // Get friends
  axios.get("/friendships/all").then(({ data }) => {
    store.dispatch("addAllFriendships", data);
  });
}

function keepYOffsetSame(el: Element) {
  const notif = el as HTMLDivElement;
  const notifManager = notif.parentElement as HTMLDivElement;

  // Make sure that if notif that's going away is last, it does same fade out effect as all notifs w/o shifting to right
  notifManager.style.width = `${notifManager.clientWidth}px`;

  // Make sure notif that's going away doesn't jump to top (b/c of absolute positioning)
  notif.style.top = `${notif.offsetTop}px`;
}

function deleteNotif(notificationId: string) {
  store.dispatch("deleteNotif", notificationId);
}

const notifications: ComputedRef<Notification[]> = computed(
  () => store.state.notifications
);

// Update
import HeyButton from "./components/HeyButton.vue";
import NextIcon from "./components/Icons/NextIcon.vue";

const lastUpdated = localStorage.getItem("lastUpdated");

async function logout() {
  try {
    await axios.delete("/sessions/delete");
  } catch {
    console.log(
      "Couldn't Delete Your Session. You May Have To Clear Your Session Cookie Manually."
    );
  }

  store.dispatch("logout");
  localStorage.setItem("lastUpdated", "5/19/24");
  router.push("/");
  router.go(0);
}
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

* {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #000;
  -webkit-font-smoothing: antialiased;
}

.notificationManager {
  position: fixed;
  top: 1em;
  right: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-radius: 0.5em;
}

.notification {
  padding: 0.75em 1em;
  border-radius: 0.5em;
  width: 17.5em;
  background-color: #fff;
  z-index: 10;
  user-select: none;
  cursor: default;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 1em 0.25em;
}

.notificationButton {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 0.5em;
  background-color: white;
  border: none;
  border-radius: 0 0.5em 0.5em 0;
  font-size: 1em;
  border-left: #e6e6e6 solid 1px;
  color: black;
  font-weight: bold;
  cursor: pointer;
}

.notification:hover > .notificationButton {
  display: block;
  animation: fade 0.25s ease;
}

.notificationTitle {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 0.85em;
}

.notificationSubtitle {
  font-weight: bold;
  margin-top: 0.2em;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notificationText {
  margin-top: 0.1em;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* New Notif */
.notif-enter-active {
  animation: notif-arrive 0.5s ease;
}

/* Notif Leave */
.notif-leave-active {
  animation: notif-leave 0.25s ease;
  position: absolute;
}

/* Move Other Notifs */
.notif-move {
  transition: all 0.25s ease;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes notif-arrive {
  0% {
    transform: translateX(18.5em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes notif-leave {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Update page */
.updateScreen {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5em;
}

.updateScreen > h1 {
  font-family: "Oceanwide", "Helvetica", "Arial", sans-serif;
}

.updateScreen > p {
  color: #afafaf;
  width: 15em;
  text-align: center;
  margin-bottom: 1em;
}
</style>
