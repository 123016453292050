<template>
  <div class="flexContainer">
    <MainSidebar />

    <FriendsSidebar
      @selected="
        (selected) => {
          currentlySelected = selected;
          error = '';
        }
      "
    />

    <div class="contentContainer noSelection" v-if="!currentlySelected">
      <p>Friends</p>
    </div>

    <div class="contentContainer addFriend" v-else-if="currentlySelected == 'send'">
      <h1>Send friend request</h1>
      <p class="friendRequestText">
        To send a friend request to someone, you’ll need their account code.
      </p>

      <div :class="['accountCodeInput', error ? 'error' : '']" ref="accountCode">
        <input
          @input="handleInput"
          @beforeinput="handleBeforeInput"
          @paste="handlePaste"
          @keydown.delete="handleDelete"
          @keydown.enter="sendFriendRequest"
          spellcheck="false"
        />
        <span>-</span>
        <input
          @input="handleInput"
          @beforeinput="handleBeforeInput"
          @paste="handlePaste"
          @keydown.delete="handleDelete"
          @keydown.enter="sendFriendRequest"
          spellcheck="false"
        />
        <span>-</span>
        <input
          @input="handleInput"
          @beforeinput="handleBeforeInput"
          @paste="handlePaste"
          @keydown.delete="handleDelete"
          @keydown.enter="sendFriendRequest"
          spellcheck="false"
        />
      </div>

      <p v-if="error" class="error">
        <span><ErrorIcon /></span>
        {{ error }}
      </p>

      <p class="friendRequestSuccess" v-if="friendRequestSentTo">
        <span><CheckmarkIcon color="#fff" /></span>
        Sent a friend request to “{{ friendRequestSentTo }}”
      </p>

      <HeyButton
        :loading="sendingFriendRequest"
        @click="sendFriendRequest"
        class="friendRequestButton"
        ><NextIcon />Send</HeyButton
      >

      <div class="divider"></div>

      <h1>Account code</h1>

      <div class="accountCodeContainer">
        <h1 class="accountCode">
          {{ store.state.current_user.code }}
        </h1>

        <div class="copyAccountCodeContainer">
          <button
            class="copyAccountCode"
            title="Copy Account Code"
            @click="copyAccountCode"
          >
            <CopyIcon />
          </button>
        </div>
      </div>

      <p>Sharing your account code lets other people send friend requests to you.</p>

      <p class="friendRequestSuccess" v-if="accountCodeCopied">
        <span><CheckmarkIcon color="#fff" /></span>
        Account code copied to clipboard!
      </p>
    </div>

    <div class="contentContainer viewFriend" v-else>
      <span class="monogram">{{ getMonogram(currentFriend.name) }}</span>

      <h1>{{ currentFriend.name }}</h1>

      <HeyButton :loading="sendingFriendRequest" @click="deleteRequest(currentlySelected)"
        ><CancelIcon color="#fff" />Unfriend</HeyButton
      >

      <HeyButton
        type="secondary"
        @click="router.push(`/messages/${currentFriend.user_id}`)"
        >Go to conversation<NextIcon color="#000"
      /></HeyButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import MainSidebar from "@/components/MainSidebar.vue";
import FriendsSidebar from "@/components/FriendsSidebar.vue";
import NextIcon from "@/components/Icons/NextIcon.vue";
import ErrorIcon from "@/components/Icons/ErrorIcon.vue";
import CancelIcon from "@/components/Icons/CancelIcon.vue";
import HeyButton from "@/components/HeyButton.vue";
import CopyIcon from "@/components/Icons/CopyIcon.vue";
import { ComputedRef, computed, onUpdated, ref } from "vue";
import { useStore } from "vuex";
import axios, { AxiosError } from "axios";
import router from "@/router";
import CheckmarkIcon from "@/components/Icons/CheckmarkIcon.vue";

// Type declations
type User = {
  user_id: string;
  name: string;
  avatar?: string;
};
type Friendship = {
  friendship_id: string;
  user: User;
};

// Vuex Store
const store = useStore();
const friends: ComputedRef<Record<string, Friendship[]>> = computed(
  () => store.state.friends
);
const currentFriend: ComputedRef<User> = computed(() => {
  const currentFriendship = store.state.friends.current.find(
    (friend: Friendship) => friend.friendship_id == currentlySelected.value
  ) as Friendship;

  return currentFriendship.user;
});

// Variables
const currentlySelected = ref("");
const oldAccountCodeValue = ref("");
const accountCode = ref<HTMLInputElement | null>(null);
const error = ref("");
const sendingFriendRequest = ref(false);
const friendRequestSentTo = ref("");
const accountCodeCopied = ref(false);

onUpdated(() => {
  if (
    !friends.value.current.find(
      (friend) => friend.friendship_id == currentlySelected.value
    ) &&
    currentlySelected.value != "send"
  ) {
    currentlySelected.value = "";
  }
});

async function deleteRequest(friendship_id: string) {
  sendingFriendRequest.value = true;
  await sleep(500);

  try {
    await axios.delete("/friendships/delete", {
      data: { friendship_id: friendship_id },
    });

    store.dispatch("deleteFriendship", friendship_id);

    if (currentlySelected.value == friendship_id) {
      currentlySelected.value = "send";
    }
    sendingFriendRequest.value = false;
  } catch (err) {
    console.log(err);
    sendingFriendRequest.value = false;
  }
}

function getAccountCode(): string | null {
  const accountCodeInputs = accountCode.value?.querySelectorAll("input");
  if (!accountCodeInputs) return null;

  let code = "";

  for (let i = 0; i < accountCodeInputs.length; i++) {
    const accountCodeInput = accountCodeInputs[i] as HTMLInputElement;

    if (accountCodeInput.value) {
      code += (i ? "-" : "") + accountCodeInput.value;
    } else {
      return "";
    }
  }

  return code;
}

function copyAccountCode() {
  if (!accountCodeCopied.value) {
    navigator.clipboard.writeText(store.state.current_user.code);
    accountCodeCopied.value = true;

    setTimeout(() => {
      accountCodeCopied.value = false;
    }, 5000);
  }
}

async function sendFriendRequest() {
  // Don't allow duplicate requests to be sent if already logging in or if there an unaddressed error
  if (sendingFriendRequest.value || error.value) return;

  // Pause script so that user can be spinner, even if response from server is fast
  sendingFriendRequest.value = true;
  await sleep(500);

  // Get account code
  const accountCode = getAccountCode();

  if (accountCode == store.state.current_user.code) {
    sendingFriendRequest.value = false;
    error.value = "You Can't Use Your Own Account Code";
    return;
  }

  if (!accountCode) {
    sendingFriendRequest.value = false;
    error.value = "Account Code Is Required";
    return;
  }

  // Check if account code is valid
  if (!isAccountCode(accountCode)) {
    sendingFriendRequest.value = false;
    error.value = "Not A Valid Account Code";
    return;
  }

  try {
    const { data } = await axios.post("/friendships/create", {
      account_code: accountCode,
    });

    store.dispatch("createNewOutgoingFriendship", {
      friendship_id: data.friendship_id,
      user: data.addressee,
    });

    sendingFriendRequest.value = false;
    emptyAccountCode();

    friendRequestSentTo.value = data.addressee.name;

    setTimeout(() => {
      friendRequestSentTo.value = "";
    }, 5000);
  } catch (err) {
    const e = err as AxiosError;
    const responseData = e.response?.data as string;

    if (responseData) {
      error.value = responseData.replaceAll(".", "");
    }

    sendingFriendRequest.value = false;
  }
}

async function handleBeforeInput(event: Event) {
  const e = event as InputEvent;
  const currentInput = e.target as HTMLInputElement;

  oldAccountCodeValue.value = currentInput.value;
}

async function handleInput(event: Event) {
  const e = event as InputEvent;
  const dataInputted = e.data?.toLowerCase() || "";
  const currentInput = e.target as HTMLInputElement;
  const nextInput = currentInput.nextElementSibling
    ?.nextElementSibling as HTMLInputElement;

  if (dataInputted == "-" && nextInput && currentInput.value.length > 3) {
    nextInput.focus();
    nextInput.select();
  }

  // Stop any invalid input conditions from happening
  if (currentInput.value.length > 6 || !isAlphabetical(dataInputted)) {
    currentInput.value = oldAccountCodeValue.value;
    return;
  }

  currentInput.value = currentInput.value.toLowerCase();
  error.value = "";
}

function handlePaste(event: Event) {
  const e = event as ClipboardEvent;
  e.preventDefault();

  if (!e.clipboardData) return; // Stop function if clipboard data doesn't exist

  const pastedData = e.clipboardData.getData("text/plain").trim();

  // Insert data into verification code fields if number 6-digits or less is pasted
  if (isAccountCode(pastedData)) {
    const inputBoxes = accountCode.value?.querySelectorAll("input");
    if (!inputBoxes) return;

    for (let i = 0; i < inputBoxes.length; i++) {
      const inputBox = inputBoxes[i] as HTMLInputElement;
      inputBox.value = "";

      if (pastedData.split("-")[i]) {
        inputBox.value = pastedData.split("-")[i];
        inputBox.focus();
      }
    }

    error.value = "";

    sendFriendRequest();
  }
}

function handleDelete(event: Event) {
  const e = event as KeyboardEvent;
  const currentInput = e.target as HTMLInputElement;
  const inputBeforeCurrent = currentInput.previousElementSibling
    ?.previousElementSibling as HTMLInputElement | null;

  if (currentInput.value.length == 0 && inputBeforeCurrent) {
    inputBeforeCurrent.focus();
  }
}

function emptyAccountCode() {
  const inputBoxes = accountCode.value?.querySelectorAll("input");
  if (!inputBoxes) return;

  for (let i = 0; i < inputBoxes.length; i++) {
    const inputBox = inputBoxes[i] as HTMLInputElement;
    inputBox.value = "";
  }

  accountCode.value?.querySelectorAll("input")[0].focus();
}

function isAlphabetical(string: string): boolean {
  if (!string) return true;

  return /^[a-z]+$/.test(string);
}

function isAccountCode(string: string): boolean {
  return /^[a-z]{3,6}-[a-z]{3,6}-[a-z]{3,6}$/.test(string);
}

async function sleep(milliseconds: number) {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve("done");
    }, milliseconds);
  });
}
function getMonogram(name: string) {
  return name.slice(0, 1).toUpperCase();
}
</script>

<style scoped>
/* Monogram */
.monogram {
  font-size: 2em;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #868686;
  height: 2em;
  width: 2em;
  border-radius: 100%;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5em;
}

/* Containers */
.contentContainer {
  align-self: stretch;
  height: 100%;
  flex-grow: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flexContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

/* No Selection Page */
.noSelection p {
  font-size: 2em;
  color: #afafaf;
}

/* Add friend page */
p {
  color: #afafaf;
  max-width: 17.5em;
  text-align: center;
}
.friendRequestText {
  margin-top: 0.25em;
}
.accountCodeInput {
  display: flex;
  margin: 2em 0 0 0;
  gap: 0.5em;
  align-items: center;
}
.accountCodeInput > span {
  color: #afafaf;
  font-weight: bold;
  font-size: 2em;
}
input {
  width: 4em;
  font-size: 2em;
  text-align: center;
  padding: 0.5em 0;
  border: #e6e6e6 solid 1px;
  border-radius: 0.25em;
}
.friendRequestButton {
  margin-top: 2em;
}
.friendRequestButton svg {
  transform: rotate(270deg);
}
.accountCode {
  margin: 0.5em 0 0.5em 0;
  background-color: #e6e6e6;
  padding: 0.75em 1em;
  border-radius: 0.25em;
  font-size: 2em;
  line-height: 1;
  font-family: "Oceanwide", "Helvetica", "Arial", sans-serif;
}
.accountCode::before {
  content: "";
  display: block;
  margin-top: 4px;
}
.friendRequestSuccess {
  color: #008a00;
  font-weight: bold;
  max-width: none;
  margin-top: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}
.friendRequestSuccess span {
  background-color: #008a00;
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.friendRequestSuccess svg {
  width: 0.75em;
  height: 0.75em;
}
.copyAccountCodeContainer {
  position: absolute;
  right: -3em;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.copyAccountCode {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 0.5em;
  height: 3em;
  width: 3em;
}
.copyAccountCode svg {
  height: 2em;
  width: 2em;
}
.copyAccountCode:hover {
  background: #e6e6e6;
}
.accountCodeContainer {
  position: relative;
}

/* View friend page */
.viewFriend button {
  margin-top: 1em;
}

/* Error */
.error input {
  border: red solid 1px;
  background-color: #ffe5e5;
  color: red;
}
.error input::placeholder {
  color: red;
}
.error span {
  color: red;
}
p.error {
  color: #ff0000;
  font-weight: bold;

  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-top: 0.75em;
  max-width: none;
}
p.error > span {
  background-color: #ff0000;
  height: 1.5em;
  width: 1.5em;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
p.error > span > * {
  height: 1em;
}

/* Headings */
h1 {
  font-family: "Oceanwide", "Helvetica", "Arial", sans-serif;
  font-size: 1.5em;
}

/* Divider */
.divider {
  width: 90%;
  margin: 2em 0 !important;
  height: 1px;
  background-color: #e6e6e6;
  margin: 0.25em 0;
}
</style>
