<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.414 22.828">
    <g transform="translate(1.414 1.414)">
      <line class="a" x1="25" transform="translate(1 10)" />
      <line class="a" x1="10" y2="10" />
      <line class="a" y1="10" x2="10" transform="translate(10 10) rotate(90)" />
    </g>
  </svg>
</template>

<style scoped>
.a {
  fill: none;
  stroke: #000;
  stroke-linecap: square;
  stroke-width: 2px;
}
</style>
