<template>
  <div class="flex-container">
    <MainSidebar />

    <div class="settings-container">
      <h1>Settings</h1>
      <HeyButton @click="logout">Logout</HeyButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import MainSidebar from "@/components/MainSidebar.vue";
import HeyButton from "@/components/HeyButton.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";

async function logout() {
  await axios.delete("/sessions/delete");
  store.dispatch("logout");
  router.push("/");
}
</script>

<style scoped>
h1 {
  font-family: "Oceanwide", "Helvetica", "Arial", sans-serif;
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.settings-container {
  align-self: stretch;
  height: 100%;
  flex-grow: 2;
  padding: 1em;
}

.flex-container {
  width: 100%;
  height: 100%;
  display: flex;
}
</style>
