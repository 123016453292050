<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <g id="Home_Icon" data-name="Home Icon" transform="translate(-13.999 -89)">
      <g id="Building" transform="translate(17.75 101.5)">
        <path
          id="Path_7"
          data-name="Path 7"
          d="M15,0V10.142H2.5V0H0V10a2.5,2.5,0,0,0,2.5,2.5H15A2.5,2.5,0,0,0,17.5,10V0Z"
          fill="#fff"
        />
      </g>
      <g id="Roof" transform="translate(13.999 89)">
        <path
          id="Path_8"
          data-name="Path 8"
          d="M13.321,1.673a2.474,2.474,0,0,1,3.5-.019l.019.019L26.933,12.079a2.288,2.288,0,0,1-1.759,3.86H4.983a2.287,2.287,0,0,1-1.757-3.86Z"
          transform="translate(-2.579 -0.939)"
          fill="none"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M26.936,12.079,16.841,1.673a2.474,2.474,0,0,0-3.517,0L3.228,12.079a2.287,2.287,0,0,0,1.759,3.86H8.833v-2.5H5.392l9.69-9.988,9.69,9.988H21.333v2.5h3.845A2.287,2.287,0,0,0,26.936,12.079Z"
          transform="translate(-2.582 -0.939)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>
