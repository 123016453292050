<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="23" viewBox="0 0 5 23">
    <g id="Group_15" data-name="Group 15" transform="translate(-804 -638)">
      <rect
        id="Rectangle_37"
        data-name="Rectangle 37"
        width="4"
        height="15"
        transform="translate(804.5 638)"
        fill="#fff"
      />
      <rect
        id="Rectangle_38"
        data-name="Rectangle 38"
        width="5"
        height="5"
        rx="2.5"
        transform="translate(804 656)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
