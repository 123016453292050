<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.5"
    height="27.5"
    viewBox="0 0 27.5 27.5"
  >
    <g id="Group_8" data-name="Group 8" transform="translate(-545.25 -229.75)">
      <line
        id="Line_9"
        data-name="Line 9"
        y1="25"
        transform="translate(559 231)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="2.5"
      />
      <line
        id="Line_10"
        data-name="Line 10"
        y1="25"
        transform="translate(571.5 243.5) rotate(90)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="2.5"
      />
    </g>
  </svg>
</template>
