<template>
  <div class="flex-container">
    <MainSidebar />

    <div class="overview-container">
      <h1>Welcome, {{ store.state.current_user.name }}.</h1>
    </div>
  </div>
</template>

<script setup lang="ts">
import MainSidebar from "@/components/MainSidebar.vue";
import { useStore } from "vuex";

const store = useStore();
</script>

<style scoped>
.flex-container {
  width: 100%;
  height: 100%;
  display: flex;
}

h1 {
  font-family: "Oceanwide", "Helvetica", "Arial", sans-serif;
  font-size: 1.5em;
}

.overview-container {
  align-self: stretch;
  height: 100%;
  flex-grow: 2;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
