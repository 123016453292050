import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import { v4 as uuidv4 } from "uuid";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

type Friendship = {
  friendship_id: string;
  user: {
    user_id: string;
    name: string;
    avatar?: string;
  };
};

type CurrentUser = {
  user_id?: string;
  email?: string;
  name?: string;
  avatar?: string;
  code?: string;
};

type Message = {
  message_id: string;
  message: string;
  group_chat: boolean;
  sent_by: string;
  sent_to_user?: string;
  sent_to_group?: string;
  date_created: string;
};

type Notification = {
  text: string;
  title: string;
  subtitle?: string;
  id: string;
};

type Store = {
  authStatus: number;
  friends: {
    current: Friendship[];
    incoming: Friendship[];
    outgoing: Friendship[];
  };
  current_user: CurrentUser;
  current_messages: Message[];
  notifications: Notification[];
};

type FriendsKeys = "current" | "incoming" | "outgoing";

export default createStore<Store>({
  state: {
    // 0 = Logged Out
    // 1 = Logged In, But Unverified
    // 2 = Logged In & Verified
    authStatus: 0,
    friends: {
      current: [],
      outgoing: [],
      incoming: [],
    },
    current_user: {},
    current_messages: [],
    notifications: [],
  },
  mutations: {
    CREATE_SESSION(state) {
      state.authStatus = 1;
    },

    VERIFY_SESSION(state, data) {
      state.authStatus = 2;
      state.current_user = data;
    },

    LOGOUT(state) {
      state.authStatus = 0;
    },
    NEW_NOTIF(state, data) {
      state.notifications.unshift(data);
    },
    DELETE_NOTIF(state, notification_id) {
      state.notifications = state.notifications.filter((notif) => {
        return notif.id != notification_id;
      });
    },

    ADD_NEW_OUTGOING_FRIENDSHIP(state, friendship) {
      state.friends.outgoing.unshift(friendship);
    },

    ADD_NEW_INCOMING_FRIENDSHIP(state, friendship) {
      state.friends.incoming.unshift(friendship);
    },

    ADD_CURRENT_FRIENDSHIP(state, friendship) {
      state.friends.current.unshift(friendship);
    },

    DELETE_FRIENDSHIP(state, friendship_id) {
      for (const i in state.friends) {
        const category = i as FriendsKeys;

        state.friends[category] = state.friends[category]?.filter((friendship) => {
          return friendship.friendship_id != friendship_id;
        });
      }
    },

    ADD_ALL_FRIENDSHIPS(state, friendships) {
      state.friends = friendships;
    },
  },
  actions: {
    createSession({ commit }) {
      commit("CREATE_SESSION");
    },
    verifySession({ commit }, data) {
      commit("VERIFY_SESSION", data);
    },
    logout({ commit }) {
      commit("LOGOUT");
    },

    newNotif({ commit }, data) {
      const id = uuidv4();

      commit("NEW_NOTIF", {
        id,
        title: data.title,
        text: data.text,
        subtitle: data.subtitle || null,
      });

      if (!data.persist) {
        setTimeout(() => {
          commit("DELETE_NOTIF", id);
        }, 5000);
      }
    },
    deleteNotif({ commit }, data) {
      commit("DELETE_NOTIF", data);
    },

    addAllFriendships({ commit }, friendships) {
      commit("ADD_ALL_FRIENDSHIPS", friendships);
    },
    createNewIncomingFriendship({ commit }, friendship) {
      this.dispatch("newNotif", {
        title: "Friends",
        text: `${friendship.user.name} sent you a friend request`,
      });

      commit("ADD_NEW_INCOMING_FRIENDSHIP", friendship);
    },
    createNewOutgoingFriendship({ commit }, friendship) {
      commit("ADD_NEW_OUTGOING_FRIENDSHIP", friendship);
    },
    deleteFriendship({ commit }, friendship_id) {
      commit("DELETE_FRIENDSHIP", friendship_id);
    },
    approveFriendship({ commit }, friendship_id) {
      // Find incoming/outgoing friendship that was approved
      const friendship =
        this.state.friends.incoming.find(
          (friend) => friend.friendship_id == friendship_id
        ) ||
        this.state.friends.outgoing.find(
          (friend) => friend.friendship_id == friendship_id
        );

      this.dispatch("newNotif", {
        title: "Friends",
        text: `You're now friends with ${friendship?.user.name}`,
      });

      commit("DELETE_FRIENDSHIP", friendship_id);

      commit("ADD_CURRENT_FRIENDSHIP", friendship);
    },
  },
  plugins: [vuexLocal.plugin],
});
