<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="37.5" viewBox="0 0 30 37.5">
    <g id="Group_23" data-name="Group 23" transform="translate(-851 -727)">
      <path
        id="Partial"
        d="M5,3A2,2,0,0,0,3,5V25a2,2,0,0,0,2,2v3H22.5V5h-3a2,2,0,0,0-2-2H5M5,0H17.5a5,5,0,0,1,5,5V25c0,2.761,0,7.385,0,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
        transform="translate(851 727)"
        fill="#868686"
      />
      <path
        id="Full"
        d="M5,3A2,2,0,0,0,3,5V25a2,2,0,0,0,2,2H17.5a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2H5M5,0H17.5a5,5,0,0,1,5,5V25a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
        transform="translate(858.5 734.5)"
        fill="#868686"
      />
    </g>
  </svg>
</template>
