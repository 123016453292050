<template>
  <h1>{{ currentText }}</h1>

  <HeyButton @click="router.push('/auth')">
    Get Started
    <NextIcon style="height: 1em" />
  </HeyButton>
</template>

<script setup lang="ts">
// Imports
import { onMounted, ref } from "vue";
import router from "@/router";
import HeyButton from "@/components/HeyButton.vue";
import NextIcon from "@/components/Icons/NextIcon.vue";
import sleep from "@/sleep";

// Variables
const heyInMultipleLanguages = ["hey", "ey", "hé", "おい", "嘿", "여기요"];
const currentText = ref("");

// Types text, then deletes it
async function typingEffect(text: string) {
  for (let i = 0; i < text.length; i++) {
    await sleep(250);

    const char = text[i];
    currentText.value += char;
  }

  await sleep(1000);

  for (let i = 0; i <= text.length; i++) {
    currentText.value = text.substring(0, text.length - i);

    await sleep(250);
  }
}
// When Welcome View is loaded, start doing typing animation
onMounted(async () => {
  let i = 0;

  while (i < heyInMultipleLanguages.length) {
    await typingEffect(heyInMultipleLanguages[i]);
    i += 1;

    // Start back at "Hey" (english)
    if (i == heyInMultipleLanguages.length) {
      i = 0;
    }
  }
});

// Update
localStorage.setItem("lastUpdated", "5/19/24");
</script>

<style scoped>
h1 {
  font-family: "Oceanwide", "Helvetica", "Arial", sans-serif;
  font-size: 3em;
  height: 1lh;
  position: relative;
  margin-bottom: 0.5em;
}

h1::after {
  margin-left: 0.2em;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0.1em;
  border-radius: 1em;
  content: "";
  background-color: #e6e6e6;
  display: inline-block;
  animation: fade-in-and-out 1s ease-out infinite;
}

@keyframes fade-in-and-out {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
