<template>
  <div class="loadingBackground" :style="styles">
    <div class="loadingForeground" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export interface Props {
  background?: string;
  loader_main?: string;
  loader_secondary?: string;
}

const props = withDefaults(defineProps<Props>(), {
  background: "#000000",
  loader_main: "#ffffff",
  loader_secondary: "#303030",
});

const styles = computed(() => {
  return `--background: ${props.background}; --loader-main: ${props.loader_main}; --loader-secondary: ${props.loader_secondary};`;
});
</script>

<style scoped>
.loadingBackground {
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  border: var(--loader-secondary) solid 0.25rem;

  animation: rotate 0.75s linear infinite;
}

.loadingForeground {
  height: 1rem;
  width: 1rem;
  border-top-left-radius: 100%;
  margin: -0.25rem 0 0 -0.25rem;
  background-color: var(--loader-main);
  position: relative;
}

.loadingForeground:before {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 100%;
  background-color: var(--background);
  position: absolute;
  left: 0;
  margin: 0.25rem 0 0 0.25rem;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
