<template>
  <div class="sidebar">
    <LogoIcon class="logo" />

    <div class="pages">
      <button ref="overview" @click="router.push('/overview')"><HomeIcon /> Home</button>
      <button ref="friends" @click="router.push('/friends')">
        <FriendIcon />Friends
        <span class="badge" v-if="incomingFriends.length">{{
          incomingFriends.length
        }}</span>
      </button>
      <button ref="settings" @click="router.push('/settings')">
        <SettingsIcon />Settings
      </button>

      <p class="title">Converstions</p>

      <button
        v-for="friend in currentFriends"
        :key="friend.friendship_id"
        @click="router.push(`/messages/${friend.user.user_id}`)"
        :class="['friend', current_user_id == friend.user.user_id ? 'selected' : '']"
      >
        <span class="monogram">{{ friend.user.name.slice(0, 1).toUpperCase() }}</span>
        <div class="friend_name">{{ friend.user.name }}</div>
      </button>

      <p v-if="!currentFriends.length" class="no_friend_msg">
        Add Friends To Start Having Conversations.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
// Imports
import HomeIcon from "@/components/Icons/HomeIcon.vue";
import FriendIcon from "@/components/Icons/FriendIcon.vue";
import SettingsIcon from "@/components/Icons/SettingsIcon.vue";
import LogoIcon from "@/components/Icons/LogoIcon.vue";
import router from "@/router";
import { ComputedRef, computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useStore } from "vuex";

// Types
type Friendship = {
  friendship_id: string;
  user: {
    user_id: string;
    name: string;
    avatar?: string;
  };
};

// Vuex
const store = useStore();
const currentFriends: ComputedRef<Friendship[]> = computed(
  () => store.state.friends.current
);
const incomingFriends: ComputedRef<Friendship[]> = computed(
  () => store.state.friends.incoming
);

const current_user_id: ComputedRef<string> = computed(
  () => router.currentRoute.value.params?.user_id as string
);

// Props/Reactive Variables/Refs
const overview = ref<HTMLButtonElement | null>(null);
const friends = ref<HTMLButtonElement | null>(null);
const settings = ref<HTMLButtonElement | null>(null);

const wsUrl = process.env.VUE_APP_WS_URL as string;
const socket = new WebSocket(wsUrl);

socket.onopen = () => {
  console.log("Connected to Hey's real-time event server");
};

socket.onmessage = (e) => {
  const { event, data } = JSON.parse(e.data);

  if (event == "FRIENDSHIP_DELETED") {
    store.dispatch("deleteFriendship", data);
  } else if (event == "FRIENDSHIP_ACCEPTED") {
    store.dispatch("approveFriendship", data);
  } else if (event == "NEW_INCOMING_FRIENDSHIP") {
    store.dispatch("createNewIncomingFriendship", data);
  } else if (event == "NEW_MESSAGE") {
    // Display notification IF user isn't already reading messages
    if (current_user_id.value != data.sent_by) {
      // Get name of person sending message
      const senderName = currentFriends.value.find(
        (friend) => friend.user.user_id == data.sent_by
      )?.user.name;

      // Create notification
      store.dispatch("newNotif", {
        title: "New Message",
        subtitle: senderName,
        text: data.message,
      });
    }

    // Add received message to list of current messages
    if (router.currentRoute.value.name == "Messages") {
      store.state.current_messages.unshift(data);
    }
  }
};

// Lifecycle Hooks
onBeforeUnmount(() => {
  socket.close();
});

onMounted(async () => {
  const currentRoute = router.currentRoute.value.name;

  document.title = `${String(currentRoute)} | Hey`;

  if (currentRoute == "Overview") {
    overview.value?.classList.add("selected");
  } else if (currentRoute == "Friends") {
    friends.value?.classList.add("selected");
  } else if (currentRoute == "Settings") {
    settings.value?.classList.add("selected");
  }
});
</script>

<style scoped>
.sidebar {
  color: white;
  background-color: black;
  height: 100%;
  width: 17.5em;
  padding: 0.75em;
  flex-shrink: 0;
}

.logo {
  height: 1.25em;
  margin: 0.5em;
  fill: white;
}

.pages > button > svg {
  width: 1.25em;
  height: 1.5em;
}

.no_friend_msg {
  color: #868686;
  margin-left: 0.75em;
}

.pages > button {
  color: white;
  padding: 0.5em 0.75em;
  border-radius: 0.5em;
  border: none;
  background: none;

  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  font-size: 1em;
  height: 2.5em;

  position: relative;
}

.pages > button:hover {
  background-color: #272727;
}

.selected {
  background-color: #363636 !important;
}
.selected:hover {
  background-color: #363636 !important;
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  margin: 1.5em 0 0.5em 0.5em;
}

p {
  color: white;
}

.friend {
  padding: 0.5em !important;
}

.monogram {
  background-color: #868686;
  height: 2em;
  width: 2em;
  border-radius: 100%;
  color: white;

  font-weight: bold;
  line-height: 2em;
  flex-shrink: 0;
}

.friend_name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
}

.badge {
  position: absolute;
  background-color: red;
  color: white;
  padding: 0 0.3em;
  font-size: 0.75em;
  border-radius: 1em;
  bottom: 0.25em;
  left: 1.75em;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
