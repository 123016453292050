import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import WelcomeView from "../views/WelcomeView.vue";
import AuthView from "@/views/AuthView.vue";
import VerifyView from "@/views/VerifyView.vue";
import store from "@/store";
import OverviewView from "@/views/OverviewView.vue";
import FriendsView from "@/views/FriendsView.vue";
import SettingsView from "@/views/SettingsView.vue";
import MessagesView from "@/views/MessagesView.vue";

const defaultRoutes = ["/", "/verify", "/overview"];

// defaultRoutes Indexes and their meanings
// 0: Welcome/Login Page
// 1: Verify Email Page
// 2: Dashboard/Chats Page

// Route only accessible to auth status passed as parameter
function mustBeAuthStatus(status: number) {
  const authStatus = store.state.authStatus;

  if (authStatus != status) {
    return defaultRoutes[authStatus];
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Welcome",
    component: WelcomeView,
    beforeEnter: () => mustBeAuthStatus(0),
  },
  {
    path: "/auth",
    name: "Authentication",
    component: AuthView,
    beforeEnter: () => mustBeAuthStatus(0),
  },
  {
    path: "/verify",
    name: "Verification",
    component: VerifyView,
    beforeEnter: () => mustBeAuthStatus(1),
  },
  {
    path: "/overview",
    name: "Overview",
    component: OverviewView,
    beforeEnter: () => mustBeAuthStatus(2),
  },
  {
    path: "/friends",
    name: "Friends",
    component: FriendsView,
    beforeEnter: () => mustBeAuthStatus(2),
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsView,
    beforeEnter: () => mustBeAuthStatus(2),
  },
  {
    path: "/messages/:user_id",
    name: "Messages",
    component: MessagesView,
    beforeEnter: () => mustBeAuthStatus(2),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
