<template>
  <div class="container">
    <LogoIcon style="height: 1.5em" />

    <h1>Verify your email</h1>
    <p class="subheading">Enter the 6-digit code sent to your email</p>

    <form ref="verificationCodeForm">
      <input
        v-for="n in 6"
        :key="n"
        pattern="[0-9]*"
        @input="handleInput"
        @paste="handlePaste"
        @keydown.delete="handleDelete"
        @keydown.enter="submitCode"
        required
        :class="error ? 'error' : ''"
      />
    </form>

    <p v-if="error" class="error">
      <span><ErrorIcon /></span>
      {{ error }}
    </p>

    <div class="navButtons">
      <HeyButton type="secondary" @click="cancelVerification"
        ><BackIcon />Cancel</HeyButton
      >
      <HeyButton :loading="verifying" @click="submitCode">Verify<NextIcon /></HeyButton>
    </div>
  </div>
</template>

<script setup lang="ts">
// Imports
import HeyButton from "@/components/HeyButton.vue";
import BackIcon from "@/components/Icons/BackIcon.vue";
import ErrorIcon from "@/components/Icons/ErrorIcon.vue";
import NextIcon from "@/components/Icons/NextIcon.vue";
import LogoIcon from "@/components/Icons/LogoIcon.vue";
import router from "@/router";
import axios from "axios";

import { computed, onMounted, ref } from "vue";

import { useStore } from "vuex";

// Vuex Store
const store = useStore();
computed(() => store.state.authStatus);

// Reactive Variables/Props
const verificationCodeForm = ref<HTMLFormElement | null>(null);
const verifying = ref(false);
const error = ref("");

// Functions
function isDigit(n: string): boolean {
  return /^\d$/.test(n);
}

function getUserTypedCode(): string {
  const inputBoxes = verificationCodeForm.value?.children as HTMLCollection;
  let code = "";

  for (let i = 0; i < inputBoxes.length; i++) {
    const inputBox = inputBoxes[i] as HTMLInputElement;
    code += inputBox.value;
  }

  return code;
}

async function handleDelete(event: Event) {
  const e = event as KeyboardEvent;
  const currentInput = e.target as HTMLInputElement;
  const inputBeforeCurrent =
    currentInput.previousElementSibling as HTMLInputElement | null;

  e.preventDefault();
  currentInput.value = "";

  if (inputBeforeCurrent) {
    inputBeforeCurrent.focus();
    inputBeforeCurrent.select();
  }
}

async function handlePaste(event: Event) {
  const e = event as ClipboardEvent;
  e.preventDefault();

  if (!e.clipboardData) return; // Stop function if clipboard data doesn't exist

  const pastedData = e.clipboardData.getData("text/plain").trim();

  // Insert data into verification code fields if number 6-digits or less is pasted
  if (/^\d{1,6}$/.test(pastedData)) {
    const inputBoxes = verificationCodeForm.value?.children as HTMLCollection;

    for (let i = 0; i < inputBoxes.length; i++) {
      const inputBox = inputBoxes[i] as HTMLInputElement;
      inputBox.value = "";

      if (pastedData[i]) {
        inputBox.value = pastedData[i];
        inputBox.focus();
      }
    }

    if (pastedData.length < 6) {
      const nextInputBox = inputBoxes[pastedData.length] as HTMLInputElement;
      nextInputBox.focus();
    } else if (pastedData.length == 6) {
      error.value = "";
      submitCode();
    }
  }
}

async function handleInput(event: Event) {
  const e = event as InputEvent;
  const dataInputted = e.data || "";
  const currentInput = e.target as HTMLInputElement;
  const oldValue = currentInput.value.replace(dataInputted, "");

  // Stop any invalid input conditions from happening
  if (dataInputted.length != 1 || !isDigit(dataInputted)) {
    currentInput.value = oldValue;
    return;
  }

  currentInput.value = dataInputted;
  error.value = "";

  // Shift focus to next input UNLESS currently focused input is the 6th (last) input
  const nextInput = currentInput.nextElementSibling as HTMLInputElement;
  if (nextInput) {
    nextInput.focus();
    nextInput.select();
  }
}

async function submitCode() {
  // Don't allow duplicate requests to be sent if already logging in or if there an unaddressed error
  if (verifying.value || error.value) return;

  // Pause script so that user can be spinner, even if response from server is fast
  verifying.value = true;
  await sleep(500);

  // Show error if not all input boxes are filled
  const code = getUserTypedCode();
  if (code.length != 6) {
    verifying.value = false;
    return (error.value = "You must fill every digit");
  }

  try {
    const res = await axios.post("/sessions/verify", { code: parseInt(code) });
    verifying.value = false;

    // Get friends
    const { data } = await axios.get("/friendships/all");
    store.dispatch("addAllFriendships", data);

    // Verify session
    store.dispatch("verifySession", res.data);

    // Send to overview
    router.push("/overview");
  } catch (err) {
    verifying.value = false;
    error.value = "Incorrect or Expired Verification Code";
  }
}

async function cancelVerification() {
  await axios.delete("/sessions/delete");
  store.dispatch("logout");
  router.push("/auth");
}

// Lifecycle Hooks
onMounted(() => {
  const firstInputBox = verificationCodeForm.value?.children[0] as HTMLInputElement;

  firstInputBox.autocomplete = "one-time-code";
  firstInputBox.focus();
});

async function sleep(milliseconds: number) {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve("done");
    }, milliseconds);
  });
}
</script>

<style scoped>
.container {
  height: 100%;
  width: 23em;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  animation: fade-in 0.5s ease;
}

.navButtons {
  margin-top: 2em;
  display: flex;
  gap: 1em;
  align-self: end;
}

form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}

input {
  width: 1.5em;
  font-size: 2em;
  text-align: center;
  padding: 0.5em 0;
  border: #e6e6e6 solid 1px;
  border-radius: 0.25em;
}

input.error {
  border: red solid 1px;
  background-color: #ffe5e5;
  color: red;
}
p.error {
  color: #ff0000;
  font-weight: bold;

  display: flex;
  align-items: center;
  gap: 0.25em;
}
p.error > span {
  background-color: #ff0000;
  height: 1.5em;
  width: 1.5em;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
p.error > span > * {
  height: 1em;
}

p {
  margin-top: 0.5em;
  color: #afafaf;
}

h1 {
  font-family: "Oceanwide", "Helvetica", "Arial", sans-serif;
  font-size: 2em;
  margin-top: 0.5em;
}
.subheading {
  margin: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.newCode {
  height: 2em;
  font-size: 1em;
  display: flex;
  gap: 0.25em;
  align-items: center;

  border: none;
  background: none;
  cursor: pointer;
  color: #afafaf;
  margin-top: 0.5em;
}

.newCode:hover {
  text-decoration: underline;
}

.newCode > img {
  height: 1.5em;
}
</style>
